<template>
     <InertiaHead title="Games"></InertiaHead>
     <loading-overlay :isLoading="loading" />
     <!-- :class="isMobile ? '' : 'play-c-padding'" -->
     <div
          class="iframe-container w-full h-full relative bg-black"
          :style="
               deviceType() != 'mobile'
                    ? 'height: calc(100vh - 64px) !important; margin-top: 64px;'
                    : 'height: calc(100vh) !important;'
          "
     >
          <iframe
               class="w-full h-full"
               v-if="url != ''"
               :src="url"
               frameborder="0"
          ></iframe>
     </div>
     <Expired />
</template>

<script>
import {
     ref,
     computed,
     onMounted,
     onUnmounted,
     onBeforeMount,
     onBeforeUnmount,
     watch
} from "vue";
import { useRoute } from "vue-router";
import { useIOSocketInit } from "composables/use-socket-init.js";
import { authStore } from "@/store/auth";
import { Head as InertiaHead } from "@inertiajs/vue3";
// import Cookies from 'js-cookie';
import Expired from "./component/expired.vue";
import { useScroll } from "composables/use-scroll.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import device from "middleware/device.js";
import { utilitiesStore } from "store/utilities.js";
import { gamesStore } from "store/games";

export default {
     components: {
          Expired,
          InertiaHead,
          LoadingOverlay
     },
     setup() {
          const route = useRoute();
          const useAuthStore = authStore();
          const { emitGameCloseIO, emitGameOpenIO } = useIOSocketInit();
          const { scrollTop } = useScroll();
          const { deviceType } = device();
          const chaportContainer = document.querySelector(".chaport-container");
          const chatButton = document.querySelector("#chat-button");
          const chatWidget = document.querySelector("#chat-widget-container");

          const useUtilitiesStore = utilitiesStore();
          const useGamesStore = gamesStore();

          const loading = ref(false);

          const preloading = computed(() => {
               return useUtilitiesStore.loading;
          });

          const isMobile = ref(false);
          // const url = ref(null);

          const url = computed(() => {
               const urlParam = useAuthStore.active_game_url;
               if (urlParam != "") {
                    if (Array.isArray(urlParam)) {
                         return decodeURIComponent(urlParam[0]);
                    } else {
                         return decodeURIComponent(urlParam);
                    }
               }
          });

          const active_coin_type = computed(() => {
               return useAuthStore.active_coin_type || route.params.type;
          });

          const myEventHandler = () => {
               if (deviceType() == "mobile") {
                    isMobile.value = true;
               } else {
                    isMobile.value = false;
               }
          };

          const handleBeforeUnload = () => {
               emitGameCloseIO();
          };

          onBeforeMount(async () => {
               emitGameOpenIO();
          });

          onMounted(async () => {
               scrollTop();
               myEventHandler();
               await useGamesStore.getProvider();

               if (!preloading.value) {
                    loading.value = true;
               }

               if (chaportContainer) {
                    chaportContainer.style.display = "none";
               }
               if (chatButton) {
                    chatButton.style.display = "none";
               }
               if (chatWidget) {
                    chatWidget.style.display = "none";
               }
               setTimeout(() => {
                    loading.value = false;
               }, 1500);

               // Add beforeunload event listener
               window.addEventListener("beforeunload", handleBeforeUnload);
          });

          onUnmounted(() => {
               if (chaportContainer) {
                    chaportContainer.style.display = "block";
               }
               if (chatButton) {
                    chatButton.style.display = "block";
               }
               if (chatWidget) {
                    chatWidget.style.display = "block";
               }
               // Remove beforeunload event listener
               window.removeEventListener("beforeunload", handleBeforeUnload);
          });

          onBeforeUnmount(() => {
               emitGameCloseIO();
               // socket.off("game:get");
          });

          watch(active_coin_type, (newVal) => {
               if (newVal) {
                    loading.value = true;
                    setTimeout(() => {
                         loading.value = false;
                    }, 1500);
               }
          });

          return {
               url,
               isMobile,
               loading,
               deviceType
          };
     }
};
</script>

<style>
.iframe-container iframe {
     width: 100%;
     height: 100%;
     border: none;
}
</style>
